import { FC, memo, ReactNode } from 'react';
import useEmblaCarousel, { EmblaOptionsType } from 'embla-carousel-react';
// Components
import { TextLink } from 'src/common/TextLink';

// Styles
import {
  Container,
  BlockContent,
  H2,
  SubtitleBlock,
  Subtitle,
  CardWrapper,
  Slider,
  SliderContainer,
  Slide,
} from './CarouselFeed.styled';

const emblaOptions: EmblaOptionsType = {
  loop: false,
  align: 'start',
  containScroll: 'trimSnaps',
  inViewThreshold: 1,
};

export type CarouselFeedItemInterface = any;

export type CarouselFeedProps = {
  title: string;
  subtitle?: string;
  showAllTitle?: string;
  showAllUrl?: string;
  items: CarouselFeedItemInterface[];
  renderItem: (item: CarouselFeedItemInterface) => ReactNode;
};

const CarouselFeed: FC<CarouselFeedProps> = ({
  title,
  showAllUrl,
  items,
  renderItem,
  subtitle = '',
  showAllTitle = 'Show all',
}) => {
  const [emblaRef] = useEmblaCarousel(emblaOptions);

  return (
    <Container>
      <BlockContent>
        <H2>{title}</H2>
        <SubtitleBlock>
          <Subtitle>{subtitle}</Subtitle>
          {showAllUrl ? (
            <TextLink href={showAllUrl} weight={700} size="l" underline>
              {showAllTitle}
            </TextLink>
          ) : null}
        </SubtitleBlock>
        <CardWrapper>
          <Slider ref={emblaRef}>
            <SliderContainer>
              {items.map((item, idx) => {
                return <Slide key={idx}>{renderItem(item)}</Slide>;
              })}
            </SliderContainer>
          </Slider>
        </CardWrapper>
      </BlockContent>
    </Container>
  );
};

export default memo(CarouselFeed);
