import styled from 'styled-components';
import { device } from 'constants/device';

export const Container = styled.section`
  width: 100%;
`;

export const BlockContent = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 48px auto 90px;
  padding: 0 108px;

  @media ${device.laptop} {
    padding: 0 58px;
  }
  @media ${device.mobileL} {
    padding: 0 16px;
  }
`;

export const H2 = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;
  color: ${({ theme }) => theme.brand.blue};
  margin-bottom: 18px;
`;

export const SubtitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  flex-wrap: wrap;
`;

export const Subtitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
`;

export const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ImageWrapper = styled.div`
  img {
    border-radius: 12px;
    width: 100%;
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  color: ${({ theme }) => theme.system.black};
`;

export const Slider = styled.div`
  overflow: hidden;
`;

export const SliderContainer = styled.div`
  display: flex;
`;

export const Slide = styled.div`
  display: flex;
  flex: 0 0 392px;
  margin-right: 24px;

  @media ${device.laptop} {
    flex: 0 0 300px;
  }
`;
